import * as lb from 'assets/provider/libraries';

const httpLink = new lb.HttpLink({
  uri: process.env.NODE_ENV === 'production' ? process.env.ENDPOINT : process.env.REACT_APP_API_SERVER,
});

const authMiddleware = new lb.ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: localStorage.getItem('token') || null,
    },
  }));

  return forward(operation);
});

export const client = new lb.ApolloClient({
  cache: new lb.InMemoryCache(),
  link: lb.concat(authMiddleware, httpLink),
});
