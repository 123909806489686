import { React } from 'assets/provider/libraries';
import * as lb from 'assets/provider/libraries';

export const ContentsTitle = props => {
  const { children } = props;

  return (
    <>
      <lb.Typography
        fontSize='contents.size'
        fontFamily='contents.font'
        fontWeight='contents.weight'
        color='text.contentsTitle'
        dangerouslySetInnerHTML={{
          __html: children,
        }}
      />
    </>
  );
};

ContentsTitle.propTypes = {
  children: lb.PropTypes.node,
};

ContentsTitle.defaultProps = {
  children: null,
};
