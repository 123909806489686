import { React } from 'assets/provider/libraries';
import * as lb from 'assets/provider/libraries';
import * as md from 'assets/provider/modules';
import * as ct from 'assets/provider/constants';

export const Topbar = () => {
  const { menus, details, overview, setOverview } = lb.useContext(md.MenuContext);
  const theme = lb.useTheme();

  const handleMenu = event => {
    window.scrollTo(0, 0);
    setOverview(md.getDetailsByCode(details, event.currentTarget.getAttribute('data-url')));
  };

  const code = overview?.map(ov => ov[ct.Subjects.CODE]).toString();

  const [anchorEl, setAnchorEl] = lb.useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = lb.useState(-1);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  return (
    <lb.Box display='flex' justifyContent='space-between' pt={1}>
      {/* LOGO */}
      <lb.Box display='flex'>
        <lb.Link
          to='/'
          data-url={process.env.REACT_APP_HOME}
          state={{ code: process.env.REACT_APP_HOME }}
          style={{ color: 'inherit', textDecoration: 'inherit' }}
          onClick={handleMenu}
        >
          <img src={md.logo} />
        </lb.Link>
      </lb.Box>

      {/* MENUS */}
      <lb.Box display='flex' alignItems='center'>
        {menus[ct.Navs.SUB]?.map(
          menu =>
            menu[ct.Menus.DEPTH] == ct.Depth.TWO && (
              <lb.Link
                to={menu[ct.Menus.PATH]}
                key={menu[ct.Menus.IDX]}
                data-url={menu[ct.Menus.CODE]}
                state={{ code: menu[ct.Menus.CODE] }}
                style={{ color: 'inherit', textDecoration: 'inherit' }}
                onClick={handleMenu}
              >
                <lb.Box pr={2}>
                  <lb.Typography
                    display='flex'
                    color='text.top'
                    fontFamily='top.font'
                    fontSize='top.size'
                    pb={0.5}
                    sx={{
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1px',
                      borderBottomColor:
                        process.env.REACT_APP_HOME !== menu[ct.Subjects.CODE] && menu[ct.Subjects.CODE] === code
                          ? 'background.contents'
                          : 'background.dark',
                      ':hover': {
                        borderBottomColor: 'background.contents',
                      },
                    }}
                  >
                    {menu[ct.Menus.ENG_NAME]}
                  </lb.Typography>
                </lb.Box>
              </lb.Link>
            ),
        )}
        <lb.Tooltip
          title={ct.LABEL.LABEL[ct.Label.MENU]}
          sx={{
            [theme.breakpoints.up('md')]: {
              display: 'none',
            },
            [theme.breakpoints.down('md')]: {
              display: 'flex',
            },
          }}
        >
          <lb.IconButton onClick={handleClick}>
            <lb.MenuRoundedIcon sx={{ color: 'background.default' }} fontSize='small' />
          </lb.IconButton>
        </lb.Tooltip>
        {/* <lb.IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode == 'light' ? <lb.DarkModeOutlinedIcon /> : <lb.LightModeOutlinedIcon />}
        </lb.IconButton> */}
      </lb.Box>

      <lb.Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        elevation={1}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        slotProps={{
          paper: {
            sx: {
              maxHeight: 'calc(100vh / 2)',
              maxWidth: 'calc(100vw / 1.5)',
              textDecoration: 'none',
            },
          },
        }}
      >
        {menus[ct.Navs.SUB]?.map((menu, index) => {
          return (
            menu[ct.Menus.DEPTH] == ct.Depth.TWO && (
              <lb.Link
                key={(index + 1) * 10}
                to={menu[ct.Menus.PATH]}
                data-url={menu[ct.Menus.CODE]}
                state={{ code: menu[ct.Menus.CODE] }}
                onClick={handleMenu}
                style={{ color: 'inherit', textDecoration: 'inherit' }}
              >
                <lb.MenuItem
                  selected={(index + 1) * 10 === selectedIndex}
                  onClick={event => handleMenuItemClick(event, (index + 1) * 10)}
                >
                  <lb.Typography fontSize='third' color='text.dark'>
                    {menu[ct.Menus.ENG_NAME]}
                  </lb.Typography>
                </lb.MenuItem>
              </lb.Link>
            )
          );
        })}
        <lb.Divider />
        {menus[ct.Navs.MAIN]?.map((menu, index) => (
          <lb.Link
            key={index}
            to={menu[ct.Subjects.PATH]}
            data-url={menu[ct.Subjects.CODE]}
            state={{ code: menu[ct.Subjects.CODE] }}
            onClick={handleMenu}
            style={{ color: 'inherit', textDecoration: 'inherit' }}
          >
            <lb.MenuItem selected={index === selectedIndex} onClick={event => handleMenuItemClick(event, index)}>
              <lb.Typography fontSize='third' color='text.dark'>
                {menu[ct.Subjects.KOR_NAME]}
              </lb.Typography>
            </lb.MenuItem>
          </lb.Link>
        ))}
      </lb.Menu>
    </lb.Box>
  );
};

Topbar.propTypes = {};
