import * as ct from 'assets/provider/constants';

export const bibleInit = {
  [ct.Subjects.CODE]: '',
};

export const postInit = {
  [ct.Subjects.MIDDLE_LEVEL_CODE]: '',
  [ct.Subjects.ORDER]: 0,
};

export const introInit = {
  [ct.Subjects.CODE]: '',
};
