import { React } from 'assets/provider/libraries';
import * as lb from 'assets/provider/libraries';
import * as md from 'assets/provider/modules';
import * as ct from 'assets/provider/constants';

export const MainList = props => {
  const { details, setOverview } = lb.useContext(md.MenuContext);
  const { list } = props;

  const handleMenu = event => {
    setOverview(md.getDetailsByCode(details, event.currentTarget.getAttribute('data-url')));
  };

  return (
    <>
      <lb.Grid container direction='row' justifyContent='space-between' alignItems='start' columns={{ sm: 6, md: 4 }}>
        {list?.map(
          (lst, index) =>
            lst[ct.Subjects.MAIN_ORDER] > 0 && (
              <lb.Grid item key={index}>
                <lb.Link
                  to={lst[ct.Subjects.PATH]}
                  key={lst[ct.Subjects.IDX]}
                  data-url={lst[ct.Subjects.CODE]}
                  state={{
                    middleLevelCode: lst[ct.Subjects.MIDDLE_LEVEL_CODE],
                    order: lst[ct.Subjects.ORDER],
                  }}
                  onClick={handleMenu}
                  style={{ color: 'inherit', textDecoration: 'inherit' }}
                >
                  <lb.Card sx={{ maxWidth: 270, marginBottom: 2.8, padding: 0 }} elevation={0}>
                    <lb.CardMedia component='img' image={md[lst[ct.Subjects.IMG_TITLE]]} alt={lst[ct.Subjects.SUB_TITLE]} />
                    <lb.CardContent sx={{ padding: 2, margin: 0 }}>
                      <lb.Typography
                        fontFamily='cardtitle.font'
                        fontSize='cardtitle.size'
                        fontWeight='cardtitle.weight'
                        color='text.title'
                        mb={1.5}
                        dangerouslySetInnerHTML={{
                          __html: lst[ct.Subjects.SUB_TITLE],
                        }}
                      ></lb.Typography>
                      <lb.Typography
                        fontFamily='cardtitle.font'
                        fontSize='third'
                        sx={{ minHeight: 70, margin: 0 }}
                        dangerouslySetInnerHTML={{
                          __html: lst[ct.Subjects.DESCRIPTION],
                        }}
                      ></lb.Typography>
                    </lb.CardContent>
                  </lb.Card>
                </lb.Link>
              </lb.Grid>
            ),
        )}
      </lb.Grid>
    </>
  );
};

MainList.propTypes = {
  list: lb.PropTypes.array,
};
