import { React } from 'assets/provider/libraries';
import * as lb from 'assets/provider/libraries';
import * as ct from 'assets/provider/constants';

export const Error = props => {
  const { code } = props;

  return (
    <>
      <lb.Box sx={{ minHeight: 'calc(100vh * 0.8)' }} display='flex' alignItems='center' justifyContent='center'>
        <lb.Box>
          <lb.Box>
            <lb.Typography fontFamily='error.font' align='center' fontSize='error.size' color='text.decorate' letterSpacing={2}>
              {ct.RESPONSE.WARNING[ct.ITEMS.TITLE]}
            </lb.Typography>
          </lb.Box>
          <lb.Box>
            <lb.Typography
              fontSize='xg'
              display='flex'
              justifyContent='center'
              dangerouslySetInnerHTML={{
                __html: ct.RESPONSE.REQUEST[code] === undefined ? code : ct.RESPONSE.REQUEST[code],
              }}
            ></lb.Typography>
          </lb.Box>
        </lb.Box>
      </lb.Box>
    </>
  );
};

Error.propTypes = {
  code: lb.PropTypes.oneOfType([lb.PropTypes.string, lb.PropTypes.object]),
};
