import { React } from 'assets/provider/libraries';
import * as lb from 'assets/provider/libraries';
import * as md from 'assets/provider/modules';
import * as cm from 'assets/provider/components';
import * as ct from 'assets/provider/constants';

export const Footer = () => {
  const { menus, details, setOverview } = lb.useContext(md.MenuContext);
  // const theme = lb.useTheme();

  const handleMenu = event => {
    window.scrollTo(0, 0);
    setOverview(md.getDetailsByCode(details, event.currentTarget.getAttribute('data-url')));
  };

  return (
    <lb.Container maxWidth={false} disableGutters sx={{ backgroundColor: 'background.footer' }}>
      <lb.Container maxWidth='lg'>
        <nav>
          <lb.Box pt={4} pb={4}>
            <lb.Grid
              container
              justifyContent='space-between'
              alignItems='start'
              columns={{ xs: 12, sm: 6, md: 6 }}
              sx={{
                flexDirection: { xl: 'row', lg: 'row', md: 'column', sm: 'column', xs: 'column' },
              }}
            >
              {menus[ct.Navs.MAIN]?.map((menu, index) => {
                return (
                  <lb.Box key={index}>
                    <lb.Link
                      to={menu[ct.Subjects.PATH]}
                      key={menu[ct.Subjects.IDX]}
                      data-url={menu[ct.Subjects.CODE]}
                      state={{ code: menu[ct.Subjects.CODE] }}
                      onClick={handleMenu}
                      className='link'
                    >
                      <lb.Typography
                        color='text.nav'
                        sx={{
                          borderBottomStyle: 'solid',
                          borderBottomWidth: '1px',
                          borderBottomColor: 'background.footer',
                          ':hover': {
                            borderBottomColor: 'background.default',
                          },
                        }}
                      >
                        {menu[ct.Subjects.ENG_NAME]}
                      </lb.Typography>
                    </lb.Link>
                  </lb.Box>
                );
              })}
            </lb.Grid>
          </lb.Box>
        </nav>
        <cm.Copyright />
      </lb.Container>
    </lb.Container>
  );
};
