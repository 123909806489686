import * as lb from 'assets/provider/libraries';

export const INTRO = lb.gql`
query intro($code: String!) {
    intro(code : $code){    
        title
        path
        contents {
            idx
            listNo
            contents
            createdBy
        }
    }
}
`;
