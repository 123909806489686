import { React } from 'assets/provider/libraries';
import * as lb from 'assets/provider/libraries';
import * as md from 'assets/provider/modules';

export const ContentsMenuTitle = props => {
  const { children } = props;
  const { main, sub } = md.getTitle(children);
  const theme = lb.useTheme();
  return (
    <>
      <lb.Grid
        display='flex'
        alignItems='baseline'
        sx={{
          flexDirection: { xl: 'row', lg: 'row', md: 'row', sm: 'column', xs: 'column' },
        }}
      >
        <lb.Box pr={1}>
          <lb.Typography
            variant='h1'
            color='text.contentsTitle'
            fontFamily='title.font'
            fontWeight='title.weight'
            dangerouslySetInnerHTML={{
              __html: main,
            }}
            sx={{
              [theme.breakpoints.up('md')]: {
                fontSize: 'title.size',
              },
              [theme.breakpoints.down('md')]: {
                fontSize: 'contents.size',
              },
            }}
          />
        </lb.Box>
        <lb.Box>
          <lb.Typography
            variant='h1'
            color='text.light'
            fontFamily='subtitle.font'
            fontSize='subtitle.size'
            fontWeight='subtitle.weight'
            dangerouslySetInnerHTML={{
              __html: sub,
            }}
          />
        </lb.Box>
      </lb.Grid>
    </>
  );
};

ContentsMenuTitle.propTypes = {
  children: lb.PropTypes.array,
};

ContentsMenuTitle.defaultProps = {
  children: null,
};
