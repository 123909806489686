import { React } from 'assets/provider/libraries';
import * as lb from 'assets/provider/libraries';
import * as md from 'assets/provider/modules';
import * as ct from 'assets/provider/constants';
import * as cm from 'assets/provider/components';

export const PageOverview = () => {
  const { state }: { state: any } = lb.useLocation();
  const { details, overview } = lb.useContext(md.MenuContext);

  const title = md.getDetailsByCode(
    details,
    state?.code
      ? state?.code
      : overview?.map(ov => (ov[ct.Menus.TOP_LEVEL_CODE] ? ov[ct.Menus.TOP_LEVEL_CODE] : ov[ct.Menus.CODE])).toString(),
  );

  return (
    <>
      {/* <lb.Helmet>
        <title>{overview ? overview?.map(child => child[ct.Menus.TITLE]) : process.env.REACT_APP_DOCUMENT_TITLE}</title>
      </lb.Helmet> */}
      <header>
        <lb.Box pt={2} pb={2}>
          {title && <cm.PageTitle>{title}</cm.PageTitle>}
        </lb.Box>
      </header>
    </>
  );
};

PageOverview.propTypes = {};
