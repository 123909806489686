import * as lb from 'assets/provider/libraries';

export const MENUS = lb.gql`
  query Menus {
    menus {
      gnbMain {
        idx
        code
        engName
        korName
        icon
        location
        hasSub
        depth
        order
        path
      }
      gnbSub {
        idx
        code
        engName
        korName
        icon
        location
        hasSub
        depth
        order
        path
      }
      lnbUser {
        idx
        code
        engName
        korName
        icon
        location
        hasSub
        depth
        order
        path
      }
      lnbItem {
        idx
        code
        engName
        korName
        icon
        location
        hasSub
        depth
        order
        path
      }
      fnb {
        idx
        code
        engName
        korName
        location
        hasSub
        depth
        order
        path
      }
    }

    details {
      menus {
        idx
        code
        korName
        title
        subTitle
        topLevelCode
        middleLevelCode
        description
        depth
        order
        hasSub
        rowNumber
        readRole
        writeRole
        path
        serviceName
        resourceName
      }
      subjects {
        idx
        code
        korName
        title
        subTitle
        topLevelCode
        middleLevelCode
        description
        depth
        order
        mainOrder
        imgTitle
        hasSub
        rowNumber
        readRole
        writeRole
        path
        serviceName
        resourceName
      }
    }
  }
`;
