import { React } from 'assets/provider/libraries';
import * as lb from 'assets/provider/libraries';

export const HtmlView = props => {
  const { children } = props;

  return <div dangerouslySetInnerHTML={{ __html: lb.DOMPurify.sanitize(children) }}></div>;
};

HtmlView.propTypes = {
  children: lb.PropTypes.node,
};

HtmlView.defaultProps = {
  children: null,
};
