/**
 * Menus Entity
 */
export const Menus = {
  IDX: 'idx',
  CODE: 'code',
  ENG_NAME: 'engName',
  KOR_NAME: 'korName',
  LOCATION: 'location',
  HAS_SUB: 'hasSub',
  TITLE: 'title',
  SUB_TITLE: 'subTitle',
  TOP_LEVEL_CODE: 'topLevelCode',
  MIDDLE_LEVEL_CODE: 'middleLevelCode',
  DESCRIPTION: 'description',
  DEPTH: 'depth',
  ICON: 'icon',
  IMG_TITLE: 'imgTitle',
  ORDER: 'order',
  MAIN_ORDER: 'mainOrder',
  BANNER_ORDER: 'bannerOrder',
  BANNER_TITLE: 'bannerTitle',
  PATH: 'path',
  SERVICE_NAME: 'serviceName',
  RESOURCE_NAME: 'resourceName',
  ROW_NUMBER: 'rowNumber',
  READ_ROLE: 'readRole',
  WRITE_ROLE: 'writeRole',
  IS_ACTIVE: 'isActive',
  CREATED_BY: 'createdBy',
  CREATED_AT: 'createdAt',
  UPDATED_BY: 'updatedBy',
  UPDATED_AT: 'updatedAt',
  UPDATED_NUMBER: 'updatedNumber',
} as const;

/**
 * Subjects Entity
 */
export const Subjects = {
  IDX: 'idx',
  CODE: 'code',
  ENG_NAME: 'engName',
  KOR_NAME: 'korName',
  LOCATION: 'location',
  HAS_SUB: 'hasSub',
  TITLE: 'title',
  SUB_TITLE: 'subTitle',
  TOP_LEVEL_CODE: 'topLevelCode',
  MIDDLE_LEVEL_CODE: 'middleLevelCode',
  DESCRIPTION: 'description',
  DEPTH: 'depth',
  ICON: 'icon',
  IMG_TITLE: 'imgTitle',
  ORDER: 'order',
  MAIN_ORDER: 'mainOrder',
  BANNER_ORDER: 'bannerOrder',
  BANNER_TITLE: 'bannerTitle',
  PATH: 'path',
  SERVICE_NAME: 'serviceName',
  RESOURCE_NAME: 'resourceName',
  ROW_NUMBER: 'rowNumber',
  READ_ROLE: 'readRole',
  WRITE_ROLE: 'writeRole',
  IS_ACTIVE: 'isActive',
  CREATED_BY: 'createdBy',
  CREATED_AT: 'createdAt',
  UPDATED_BY: 'updatedBy',
  UPDATED_AT: 'updatedAt',
  UPDATED_NUMBER: 'updatedNumber',
  CONTENTS: 'contents',
} as const;

/**
 * Contents Entity
 */
export const Contents = {
  IDX: 'idx',
  LIST_NO: 'listNo',
  CONTENTS: 'contents',
  HIT: 'hit',
  DOWNLOAD: 'download',
  PRINT: 'print',
  LAST_VISIT: 'lastVisit',
  CREATED_BY: 'createdBy',
  CREATED_AT: 'createdAt',
  UPDATED_BY: 'updatedBy',
  UPDATED_AT: 'updatedAt',
  UPDATED_NUMBER: 'updatedNumber',
} as const;
