import { React } from 'assets/provider/libraries';
import * as lb from 'assets/provider/libraries';
import * as md from 'assets/provider/modules';
import 'normalize.css';
import 'index.css';

const root = lb.ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <lb.ApolloProvider client={md.client}>
      <lb.BrowserRouter>
        <md.App />
      </lb.BrowserRouter>
    </lb.ApolloProvider>
  </React.StrictMode>,
);
