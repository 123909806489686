import { React } from 'assets/provider/libraries';
import * as lb from 'assets/provider/libraries';
import * as md from 'assets/provider/modules';
import * as ct from 'assets/provider/constants';

export const BibleList = props => {
  const { list } = props;
  const { details, setOverview } = lb.useContext(md.MenuContext);
  const [expanded, setExpanded] = lb.useState<string | false>(false);

  const handleMenu = event => {
    setOverview(md.getOverviewByCode(details?.subjects, event.currentTarget.getAttribute('data-code')));
  };

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <lb.Box mt={4}>
        <lb.Typography fontSize='xg' fontWeight='bold'>
          Related Posts
        </lb.Typography>
      </lb.Box>
      {list?.map(
        (top, index) =>
          top[ct.Subjects.DEPTH] === ct.Depth.ONE && (
            <lb.Box key={index}>
              <lb.Accordion
                expanded={expanded === top[ct.Subjects.IDX]}
                onChange={handleChange(top[ct.Subjects.IDX])}
                sx={{ margin: 0, border: 0, padding: 0, boxShadow: 0, zIndex: 1, backgroundColor: 'background.default' }}
              >
                {!top[ct.Subjects.HAS_SUB] && (
                  <lb.Link
                    to={top[ct.Subjects.PATH]}
                    key={top[ct.Subjects.IDX]}
                    data-code={top[ct.Subjects.CODE]}
                    state={{
                      middleLevelCode: top[ct.Subjects.MIDDLE_LEVEL_CODE],
                      order: top[ct.Subjects.ORDER],
                      path: top[ct.Subjects.PATH],
                    }}
                    onClick={handleMenu}
                    className='link'
                  >
                    <lb.AccordionSummary sx={{ padding: 0, margin: 0 }}>
                      <lb.Box display='flex' alignItems='center'>
                        <lb.FiberManualRecordIcon
                          sx={{
                            paddingRight: 2,
                            color: 'text.decorate',
                            fontSize: '1.5rem',
                          }}
                        />
                        <lb.Typography color='text.primary'>{top[ct.Subjects.KOR_NAME]}</lb.Typography>
                      </lb.Box>
                    </lb.AccordionSummary>
                  </lb.Link>
                )}
                {top[ct.Subjects.HAS_SUB] && (
                  <lb.AccordionSummary expandIcon={top[ct.Subjects.HAS_SUB] && <lb.ExpandMore />} sx={{ padding: 0, margin: 0 }}>
                    <lb.Box display='flex' alignItems='center'>
                      <lb.FiberManualRecordIcon
                        sx={{
                          paddingRight: 2,
                          paddingTop: 1,
                          color: 'text.decorate',
                          fontSize: '1.5rem',
                        }}
                      />
                      <lb.Typography fontWeight={expanded === top[ct.Subjects.IDX] ? 'bold' : 'regular'}>
                        {top[ct.Subjects.KOR_NAME]}
                      </lb.Typography>
                    </lb.Box>
                  </lb.AccordionSummary>
                )}
                {list?.map(
                  (middle, index) =>
                    middle[ct.Subjects.DEPTH] === ct.Depth.TWO &&
                    top[ct.Subjects.CODE] === middle[ct.Subjects.MIDDLE_LEVEL_CODE] && (
                      <lb.AccordionDetails key={index}>
                        <lb.Link
                          to={middle[ct.Subjects.PATH]}
                          key={middle[ct.Subjects.IDX]}
                          data-code={middle[ct.Subjects.CODE]}
                          state={{
                            middleLevelCode: middle[ct.Subjects.MIDDLE_LEVEL_CODE],
                            order: middle[ct.Subjects.ORDER],
                            path: middle[ct.Subjects.PATH],
                          }}
                          onClick={handleMenu}
                          className='link'
                        >
                          <lb.Typography pl={1} color='text.primary' textAlign='left'>
                            {middle[ct.Subjects.KOR_NAME]}
                          </lb.Typography>
                        </lb.Link>
                      </lb.AccordionDetails>
                    ),
                )}
              </lb.Accordion>
            </lb.Box>
          ),
      )}
    </>
  );
};

BibleList.propTypes = {
  list: lb.PropTypes.array,
};
