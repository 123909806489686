import { React } from 'assets/provider/libraries';
import * as lb from 'assets/provider/libraries';

export const Copyright = () => {
  return (
    <lb.Box textAlign='center' color='text.gray' pb={2}>
      <lb.Box pb={1}>
        <lb.Typography fontSize='sm'>
          승인없이 www.bibletobible.org (시냇가에 심은 나무) 사이트의 모든 콘텐츠, 정보등을 무단으로 이용하실 수 없습니다.
        </lb.Typography>
      </lb.Box>
      <lb.Box>
        <lb.Typography fontSize='sm'>Copyright 1999 By 시냇가에 심은 나무 ALL RIGHTS RESERVED.</lb.Typography>
      </lb.Box>
    </lb.Box>
  );
};

Copyright.propTypes = {};
