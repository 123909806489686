import { React } from 'assets/provider/libraries';
import * as lb from 'assets/provider/libraries';
import * as md from 'assets/provider/modules';
import * as ct from 'assets/provider/constants';

export const Navbar = () => {
  const { menus, details, overview, setOverview } = lb.useContext(md.MenuContext);
  const theme = lb.useTheme();

  const handleMenu = event => {
    window.scrollTo(0, 0);
    setOverview(md.getDetailsByCode(details, event.currentTarget.getAttribute('data-url')));
  };

  const code = overview?.map(ov => ov[ct.Subjects.CODE]).toString();

  return (
    <nav>
      <lb.Box
        pb={2}
        sx={{
          [theme.breakpoints.up('md')]: {
            display: 'flex',
          },
          [theme.breakpoints.down('md')]: {
            display: 'none',
          },
        }}
      >
        <lb.Grid container direction='row' justifyContent='space-between' alignItems='start' columns={{ xs: 12, sm: 6, md: 6 }}>
          {menus[ct.Navs.MAIN]?.map(menu => (
            <lb.Link
              to={menu[ct.Subjects.PATH]}
              key={menu[ct.Subjects.IDX]}
              data-url={menu[ct.Subjects.CODE]}
              state={{ code: menu[ct.Subjects.CODE] }}
              onClick={handleMenu}
              style={{ color: 'inherit', textDecoration: 'inherit' }}
            >
              <lb.Typography
                color='text.nav'
                pb={0.5}
                sx={{
                  borderBottomStyle: 'solid',
                  borderBottomWidth: '2px',
                  borderBottomColor: menu[ct.Subjects.CODE] === code ? 'background.default' : 'background.dark',
                  ':hover': {
                    borderBottomColor: 'background.default',
                  },
                }}
              >
                {menu[ct.Subjects.KOR_NAME]}
              </lb.Typography>
            </lb.Link>
          ))}
        </lb.Grid>
      </lb.Box>
    </nav>
  );
};
