import * as lb from 'assets/provider/libraries';

export const tokens = mode => ({
  ...(mode === 'light'
    ? {
        gray: {
          100: '#e0e0e0',
          200: '#c2c2c2',
          300: '#a3a3a3',
          400: '#858585',
          500: '#666666',
          600: '#525252',
          700: '#3d3d3d',
          800: '#292929',
          900: '#141414',
        },
        dark: {
          100: '#d5d6d6',
          200: '#abadad',
          300: '#808585',
          400: '#565c5c',
          500: '#2c3333', // dark (footer)
          600: '#232929',
          700: '#1a1f1f',
          800: '#121414',
          900: '#090a0a',
        },
        text: {
          100: '#cfced3',
          200: '#9e9ea7',
          300: '#6e6d7a',
          400: '#3d3d4e',
          500: '#0d0c22', // default (text)
          600: '#0a0a1b',
          700: '#080714',
          800: '#05050e',
          900: '#030207',
        },
        background: {
          100: '#fafdfc',
          200: '#f5fbfa',
          300: '#f1faf7',
          400: '#ecf8f5',
          500: '#dee1e6', //'#e7f6f2', // lightback (main)
          600: '#b9c5c2',
          700: '#8b9491',
          800: '#5c6261',
          900: '#2e3130',
        },
        primary: {
          100: '#d7dee0',
          200: '#b0bdc1',
          300: '#889da2',
          400: '#617c83',
          500: '#3F4E4F', //'#395b64', // darkback (header)
          600: '#2e4950',
          700: '#22373c',
          800: '#172428',
          900: '#0b1214',
        },
        menu: {
          100: '#edf4f4',
          200: '#dbe9ea',
          300: '#c9dfdf',
          400: '#b7d4d5',
          500: '#9C9675', //'#a5c9ca', // topmenu
          600: '#84a1a2',
          700: '#637979',
          800: '#425051',
          900: '#212828',
        },
      }
    : {
        gray: {
          100: '#141414',
          200: '#292929',
          300: '#3d3d3d',
          400: '#525252',
          500: '#666666',
          600: '#858585',
          700: '#a3a3a3',
          800: '#c2c2c2',
          900: '#e0e0e0',
        },
        dark: {
          100: '#090a0a',
          200: '#121414',
          300: '#1a1f1f',
          400: '#232929',
          500: '#2c3333',
          600: '#565c5c',
          700: '#808585',
          800: '#abadad',
          900: '#d5d6d6',
        },
        text: {
          100: '#2e3130',
          200: '#5c6261',
          300: '#8b9491',
          400: '#b9c5c2',
          500: '#e7f6f2',
          600: '#ecf8f5',
          700: '#f1faf7',
          800: '#f5fbfa',
          900: '#fafdfc',
        },
        background: {
          100: '#0b1214',
          200: '#172428',
          300: '#22373c',
          400: '#2e4950',
          500: '#395b64',
          600: '#617c83',
          700: '#889da2',
          800: '#b0bdc1',
          900: '#d7dee0',
        },
        primary: {
          100: '#d7dee0',
          200: '#b0bdc1',
          300: '#889da2',
          400: '#617c83',
          500: '#395b64', // darkback (header)
          600: '#2e4950',
          700: '#22373c',
          800: '#172428',
          900: '#0b1214',
        },
        menu: {
          100: '#212828',
          200: '#425051',
          300: '#637979',
          400: '#84a1a2',
          500: '#a5c9ca',
          600: '#b7d4d5',
          700: '#c9dfdf',
          800: '#dbe9ea',
          900: '#edf4f4',
        },
      }),
});

// MUI Theme Settings
export const themeSettings = mode => {
  const colors = tokens(mode);

  return {
    palette: {
      mode,
      ...(mode === 'light'
        ? {
            primary: {
              main: colors.primary[500],
            },
            background: {
              default: colors.background[500],
              dark: colors.primary[500],
              footer: colors.dark[500],
              contents: colors.menu[500],
            },
            text: {
              primary: colors.text[500],
              title: colors.dark[500],
              top: colors.menu[500],
              nav: colors.background[100],
              decorate: '#9C9675', //'#E17A70',
              light: colors.background[500],
              gray: colors.gray[500],
              white: '#ffffff',
              contentsTitle: '#191717',
            },
          }
        : {
            primary: {
              main: colors.primary[500],
            },
            background: {
              // default: colors.background[500],
              // dark: colors.dark[100],
              // footer: colors.dark[100],
              // contents: colors.menu[500],
            },
            text: {
              primary: colors.text[100],
              title: colors.dark[500],
              top: colors.menu[500],
              nav: colors.background[100],
              decorate: '#E17A70',
              light: colors.gray[500],
              white: '#ffffff',
            },
          }),
    },
    typography: {
      fontFamily: ['Nanum Gothic', 'sans-serif'].join(','),
      pagetitle: {
        font: ['Noto Sans KR', 'sans-serif'].join(','),
        size: '1.25rem',
        weight: 500,
      },
      pagesubtitle: {
        font: ['Roboto', 'sans-serif'].join(','),
        size: '1rem',
        weight: 500,
      },
      cardtitle: {
        font: ['Noto Sans KR', 'sans-serif'].join(','),
        size: '0.95rem',
        weight: 400,
      },
      title: {
        font: ['Noto Sans KR', 'sans-serif'].join(','),
        size: '1rem',
        weight: 400,
      },
      subtitle: {
        font: ['Roboto', 'sans-serif'].join(','),
        size: '0.75rem',
        weight: 400,
      },
      contents: {
        font: ['Noto Sans KR', 'sans-serif'].join(','),
        size: '1rem',
        weight: 500,
      },
      items: {
        font: ['Roboto', 'sans-serif'].join(','),
        size: '0.75rem',
        weight: 500,
      },
      top: {
        font: ['Roboto', 'sans-serif'].join(','),
        size: '0.9375rem',
        weight: 400,
      },
      error: {
        font: ['Macondo Swash Caps', 'cursive'].join(','),
        size: '5rem',
      },
      warning: {
        font: ['Macondo Swash Caps', 'cursive'].join(','),
        size: '3rem',
      },
      primary: '1.05rem',
      secondary: '0.9rem',
      third: '0.85rem',
      h1: {
        size: '3rem',
      },
      h2: {
        size: '2.25rem',
      },
      h3: {
        size: '1.75rem',
      },
      xg: '1.5rem',
      lg: '1.25rem',
      md: '0.9375rem',
      sm: '0.75rem',
      xs: '0.625rem',
      weight: {
        thin: 100,
        light: 300,
        regular: 400,
        normal: 500,
        medium: 600,
        bold: 700,
        extra: 800,
        black: 900,
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 992,
        lg: 1200,
        xl: 1536,
      },
    },
  };
};

export const useMode = () => {
  const [mode, setMode] = lb.useState('light');

  const colorMode = lb.useMemo(
    () => ({
      toggleColorMode: () => setMode(prev => (prev === 'light' ? 'dark' : 'light')),
    }),
    [],
  );

  const theme: any = lb.useMemo(() => lb.createTheme(themeSettings(mode)), [mode]);

  return [theme, colorMode];
};
