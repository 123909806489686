import * as lb from 'assets/provider/libraries';

const MenuContext = lb.createContext<any>({
  menus: null,
  setMenus: () => {},
  details: null,
  setDetails: () => {},
  overview: null,
  setOverview: () => {},
});

export default MenuContext;
