/** React */
import React from 'react';
export { React };
export { createContext, useContext, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom/client';
export { ReactDOM };
export { BrowserRouter, Route, Routes, Link, useLocation } from 'react-router-dom';
export { useParams } from 'react-router';
import { createBrowserHistory } from 'history';
export const history = createBrowserHistory();

/** Apollo Server */
export { ApolloClient, ApolloLink, ApolloProvider, concat, gql, HttpLink, InMemoryCache, useQuery } from '@apollo/client';

/** Material UI */
export { Box, CssBaseline, Container, createTheme, IconButton, Toolbar, Typography, useTheme } from '@mui/material';
export { Card, CardMedia, CardContent, Grid, Backdrop, CircularProgress, AppBar } from '@mui/material';
export { Accordion, AccordionDetails, AccordionSummary, Button, Menu, MenuItem, Tooltip, Divider } from '@mui/material';
export { Pagination, PaginationItem, Stack } from '@mui/material';

export { ThemeProvider } from '@mui/material/styles';
export {
  LightModeOutlined as LightModeOutlinedIcon,
  DarkModeOutlined as DarkModeOutlinedIcon,
  FiberManualRecord as FiberManualRecordIcon,
  MenuRounded as MenuRoundedIcon,
  ArrowDropDown as ArrowDropDownIcon,
  Home as HomeIcon,
  MailOutline as MailOutlineIcon,
  AutoStories as AutoStoriesIcon,
  ExpandMore,
  NavigateBefore,
  NavigateNext,
  SkipNext,
  SkipPrevious,
} from '@mui/icons-material';

/** Helmet */
export { HelmetProvider, Helmet } from 'react-helmet-async';

/** DOMPurify */
import DOMPurify from 'dompurify';
export { DOMPurify };

/** PropTypes */
import PropTypes from 'prop-types';
export { PropTypes };
