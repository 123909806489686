export const Navs = {
  MAIN: 'gnbMain',
  SUB: 'gnbSub',
  ITEM: 'lnbItem',
  FNB: 'fnb',
  USER: 'lnbUser',
  DETAILS: 'details',
} as const;

export const Depth = {
  ZERO: 'ZERO',
  ONE: 'ONE',
  TWO: 'TWO',
  THREE: 'THREE',
} as const;

export const Location = {
  INNER: 'INNER',
  MAIN: 'MAIN',
  SUB: 'SUB',
  USER: 'USER',
  ITEM: 'ITEM',
  FOOTER: 'FOOTER',
} as const;

export const Role = {
  GUEST: 'GUEST',
  USER: 'USER',
  MANAGER: 'MANAGER',
};

export const Side = {
  COMPONENT: 'component',
  DATA: 'data',
};

export const Label = {
  BY: 'by',
  NEXT: 'next',
  PREV: 'prev',
  REFER: 'refer',
  RELATED_LINK: 'related_link',
  MENU: 'menu',
  CONTACT: 'contact',
};
