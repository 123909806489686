import { React } from 'assets/provider/libraries';
import * as lb from 'assets/provider/libraries';
import * as md from 'assets/provider/modules';
import * as ct from 'assets/provider/constants';

export const PageTitle = props => {
  const { children } = props;
  const { main, sub } = md.getTitle(children);

  return (
    <>
      <lb.Box display='flex' justifyContent='space-between' alignItems='baseline'>
        <lb.Box display='flex' alignItems='baseline' pb={1}>
          <lb.Typography
            pr={1}
            fontFamily='pagetitle.font'
            fontSize='pagetitle.size'
            fontWeight='pagetitle.weight'
            color='text.title'
          >
            {main}
          </lb.Typography>
          <lb.Typography
            fontFamily='pagesubtitle.font'
            fontSize='pagesubtitle.size'
            fontWeight='pagesubtitle.weight'
            color='text.title'
          >
            {sub}
          </lb.Typography>
        </lb.Box>
        <lb.Box display='flex' alignItems='center'>
          <lb.MailOutlineIcon fontSize='small' />
          <lb.Typography pl={1}>{ct.LABEL.LABEL[ct.Label.CONTACT]}</lb.Typography>
        </lb.Box>
      </lb.Box>
    </>
  );
};

PageTitle.propTypes = {
  children: lb.PropTypes.array,
};

PageTitle.defaultProps = {
  children: null,
};
