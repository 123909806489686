import { React } from 'assets/provider/libraries';
import * as lb from 'assets/provider/libraries';
import * as ct from 'assets/provider/constants';

const Error404 = () => {
  return (
    <>
      <lb.Box sx={{ minHeight: 'calc(100vh * 0.8)' }} display='flex' alignItems='center' justifyContent='center'>
        <lb.Box>
          <lb.Box display='flex' alignItems='baseline' justifyContent='center'>
            <lb.Typography fontFamily='error.font' fontSize='error.size'>
              {ct.RESPONSE.RESPONSE[ct.ERROR_CODE.NOT_FOUND]}
            </lb.Typography>
            <lb.Typography pl={2} fontFamily='warning.font' fontSize='warning.size'>
              {ct.ERROR_CODE.NOT_FOUND}
            </lb.Typography>
          </lb.Box>
          <lb.Box display='flex' justifyContent='center'>
            <lb.Typography fontSize='xg'>{ct.RESPONSE.WARNING[ct.ERROR_CODE.NOT_FOUND]}</lb.Typography>
          </lb.Box>
        </lb.Box>
      </lb.Box>
    </>
  );
};

export default Error404;
