import * as lb from 'assets/provider/libraries';

export const LIST = lb.gql`
query list($code: String!) {
    list(code : $code){
      contents {
        idx
        listNo
        contents
      }
      subjects {
        idx
        code
        korName
        title
        subTitle
        depth
        hasSub
        topLevelCode
        middleLevelCode
        path
        order
      }
      count
    }
  }
`;

export const POST = lb.gql`
query post($middleLevelCode: String!, $order: Int!) {
    post(middleLevelCode : $middleLevelCode, order: $order){    
        title
        path
        contents {
            idx
            listNo
            contents
            createdBy
        }
        count
    }
}
`;
