import { React } from 'assets/provider/libraries';
import * as lb from 'assets/provider/libraries';
import * as md from 'assets/provider/modules';
import * as ct from 'assets/provider/constants';
import * as cm from 'assets/provider/components';

export const ContentsNavbar = () => {
  const { menus, details, setOverview, overview } = lb.useContext(md.MenuContext);
  const { pathname }: { pathname: string } = lb.useLocation();

  const handleMenu = event => {
    window.scrollTo(0, 0);
    setOverview(md.getDetailsByCode(details, event.currentTarget.getAttribute('data-url')));
  };

  const title = md.getDetailsByCode(
    details,
    overview?.map(ov => (ov[ct.Menus.TOP_LEVEL_CODE] ? ov[ct.Menus.TOP_LEVEL_CODE] : ov[ct.Menus.CODE])).toString(),
  );

  const subtitle = md
    .getDetailsByCode(details, overview?.map(ov => ov[ct.Menus.CODE]).toString())
    ?.map(sub => sub[ct.Subjects.TITLE]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(overview?.map(ov => ov[ct.Subjects.ORDER]) - 1);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  return (
    <>
      <lb.Box display='flex' alignItems='center' pt={1} pb={1}>
        {/* Menu Title */}
        <lb.Box flex={1} right={5} display='flex' justifyContent='left'>
          <lb.Link
            to={process.env.REACT_APP_INTRODUCTION ? process.env.REACT_APP_INTRODUCTION : '/bible'}
            data-url={
              overview?.map(ov => ov[ct.Subjects.TOP_LEVEL_CODE]).toString()
                ? overview?.map(ov => ov[ct.Subjects.TOP_LEVEL_CODE]).toString()
                : overview?.map(ov => ov[ct.Subjects.CODE]).toString()
            }
            state={{
              code: overview?.map(ov => ov[ct.Subjects.TOP_LEVEL_CODE]).toString()
                ? overview?.map(ov => ov[ct.Subjects.TOP_LEVEL_CODE]).toString()
                : overview?.map(ov => ov[ct.Subjects.CODE]).toString(),
            }}
            onClick={handleMenu}
            style={{ color: 'inherit', textDecoration: 'inherit' }}
          >
            {title && <cm.ContentsMenuTitle>{title}</cm.ContentsMenuTitle>}
          </lb.Link>
        </lb.Box>
        {/* Contents Title */}
        <lb.Box flex={1.5} display='flex' justifyContent='center'>
          {subtitle && <cm.ContentsTitle>{subtitle}</cm.ContentsTitle>}
        </lb.Box>
        {/* Drop Menus */}
        <lb.Box flex={1} display='flex' justifyContent='right' left={5}>
          <lb.Link
            to='/'
            data-url={process.env.REACT_APP_HOME}
            state={{ code: process.env.REACT_APP_HOME }}
            style={{ color: 'inherit', textDecoration: 'inherit' }}
            onClick={handleMenu}
          >
            <lb.Tooltip title='Home'>
              <lb.IconButton>
                <lb.HomeIcon sx={{ color: 'text.light' }} fontSize='small' />
              </lb.IconButton>
            </lb.Tooltip>
          </lb.Link>
          <lb.Tooltip title={ct.LABEL.LABEL[ct.Label.MENU]}>
            <lb.IconButton onClick={handleClick}>
              <lb.MenuRoundedIcon sx={{ color: 'text.light' }} fontSize='small' />
            </lb.IconButton>
          </lb.Tooltip>
        </lb.Box>
      </lb.Box>

      <lb.Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        elevation={1}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        slotProps={{
          paper: {
            sx: {
              maxHeight: 'calc(100vh / 2)',
              maxWidth: 'calc(100vw / 1.5)',
              textDecoration: 'none',
            },
          },
        }}
      >
        {menus[ct.Navs.SUB]?.map((menu, index) => {
          return (
            menu[ct.Menus.DEPTH] == ct.Depth.TWO && (
              <lb.Link
                key={(index + 1) * 10}
                to={menu[ct.Menus.PATH]}
                data-url={menu[ct.Menus.CODE]}
                state={{ code: menu[ct.Menus.CODE] }}
                onClick={handleMenu}
                style={{ color: 'inherit', textDecoration: 'inherit' }}
              >
                <lb.MenuItem
                  selected={(index + 1) * 10 === selectedIndex}
                  onClick={event => handleMenuItemClick(event, (index + 1) * 10)}
                >
                  <lb.Typography fontSize='third' color='text.dark'>
                    {menu[ct.Menus.ENG_NAME]}
                  </lb.Typography>
                </lb.MenuItem>
              </lb.Link>
            )
          );
        })}
        <lb.Divider />
        {menus[ct.Navs.MAIN]?.map((menu, index) => (
          <lb.Link
            key={index}
            to={menu[ct.Subjects.PATH]}
            data-url={menu[ct.Subjects.CODE]}
            state={{ code: menu[ct.Subjects.CODE] }}
            onClick={handleMenu}
            style={{ color: 'inherit', textDecoration: 'inherit' }}
          >
            <lb.MenuItem selected={index === selectedIndex} onClick={event => handleMenuItemClick(event, index)}>
              <lb.Typography fontSize='third' color='text.dark'>
                {menu[ct.Subjects.KOR_NAME]}
              </lb.Typography>
            </lb.MenuItem>
          </lb.Link>
        ))}
      </lb.Menu>
      {/* Contents Title */}
      {process.env.REACT_APP_CONTENTS?.includes(pathname) && subtitle && (
        <lb.Box textAlign='center' display='none'>
          <cm.ContentsTitle>{subtitle}</cm.ContentsTitle>
        </lb.Box>
      )}
    </>
  );
};

ContentsNavbar.propTypes = {};
