import * as lb from 'assets/provider/libraries';
import * as md from 'assets/provider/modules';
import * as ct from 'assets/provider/constants';

export const getDetailsByCode = (details, code) => {
  const menus = details?.menus?.filter(detail => detail[ct.Menus.CODE] === code);
  if (md.isNull(menus)) {
    const subjects = details?.subjects?.filter(subject => subject[ct.Subjects.CODE] === code);
    return subjects;
  } else {
    return menus;
  }
};

export const getOverviewByCode = (subjects, code) => {
  if (!md.isNull(subjects)) return subjects?.filter(subject => subject[ct.Subjects.CODE] === code);

  return null;
};

export const getOverviewByOrder = (subjects, middleLevelCode, order) => {
  if (!md.isNull(subjects))
    return subjects?.filter(
      subject =>
        subject[ct.Subjects.MIDDLE_LEVEL_CODE] === middleLevelCode.toString() && Number(subject[ct.Subjects.ORDER]) == order,
    );
  return null;
};

export const getTitle = title => {
  const main = md.isNull(title) ? process.env.REACT_APP_DOCUMENT_TITLE : title?.map(detail => detail[ct.Subjects.TITLE]);
  const sub = title?.map(detail => detail[ct.Subjects.SUB_TITLE]);

  return { main, sub };
};

export const useQuery = (gql, variables) => {
  const [errors, setErrors] = lb.useState('');

  const { loading, error, data, refetch } = lb.useQuery(gql, {
    variables,
    fetchPolicy: ct.Policy.CAN,
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        const message = graphQLErrors.map(({ message }) => message);
        setErrors(message.toString());
      }

      if (networkError) {
        setErrors(ct.RESPONSE.NETWORK);
      }
    },
  });

  return { loading, error, data, refetch, errors };
};
