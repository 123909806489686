import { React } from 'assets/provider/libraries';
import * as lb from 'assets/provider/libraries';

export const BackdropNotClose = () => {
  const [open] = lb.useState(true);

  return (
    <>
      <lb.Backdrop sx={{ color: 'white', zIndex: theme => theme.zIndex.drawer + 1 }} open={open}>
        <lb.CircularProgress color='inherit' />
      </lb.Backdrop>
    </>
  );
};

BackdropNotClose.propTypes = {};
