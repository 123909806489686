import { React } from 'assets/provider/libraries';
import * as lb from 'assets/provider/libraries';
import * as md from 'assets/provider/modules';
import * as cm from 'assets/provider/components';
import * as ct from 'assets/provider/constants';

export const HeaderLayout = () => {
  const { menus, setMenus, setDetails, overview } = lb.useContext(md.MenuContext);
  const { pathname } = lb.useLocation();

  const { loading, error, data } = lb.useQuery(md.MENUS, {
    fetchPolicy: ct.Policy.CAN,
  });

  lb.useEffect(() => {
    if (data) {
      setMenus(data?.menus);
      setDetails(data?.details);
    }
  }, [data, setMenus, setDetails]);

  return (
    <>
      <lb.Helmet>
        <title>{overview ? overview?.map(ov => ov[ct.Menus.TITLE]) : process.env.REACT_APP_DOCUMENT_TITLE}</title>
      </lb.Helmet>
      {loading && <cm.BackdropNotClose />}
      {!loading && !error && !md.isNull(menus[ct.Navs.MAIN]) && process.env.REACT_APP_NAVBAR?.includes(pathname) && (
        <>
          <lb.AppBar position='sticky'>
            <lb.Container maxWidth={false} disableGutters sx={{ backgroundColor: 'background.dark' }}>
              <lb.Container maxWidth='lg'>
                <cm.Topbar />
                <cm.Navbar />
              </lb.Container>
            </lb.Container>
          </lb.AppBar>
          {process.env.REACT_APP_CONTACT?.includes(pathname) && (
            <lb.Container maxWidth={false} disableGutters sx={{ backgroundColor: 'background.default' }}>
              <lb.Container maxWidth='lg'>
                <cm.PageOverview />
              </lb.Container>
            </lb.Container>
          )}
        </>
      )}
      {!loading && !error && !md.isNull(menus[ct.Navs.MAIN]) && !process.env.REACT_APP_NAVBAR?.includes(pathname) && (
        <>
          <lb.AppBar position='sticky'>
            <lb.Container maxWidth={false} disableGutters sx={{ backgroundColor: 'background.contents' }}>
              <lb.Container maxWidth='lg'>
                <cm.ContentsNavbar />
              </lb.Container>
            </lb.Container>
          </lb.AppBar>
        </>
      )}
    </>
  );
};

HeaderLayout.propTypes = {};
