import * as lb from 'assets/provider/libraries';

/**
 * Move to the top of the page, when accessing a page or switching pages.
 *
 * @author Elena
 * @date 2023-03-03
 */
export const useScrollToTop = () => {
  const { pathname } = lb.useLocation();

  lb.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};
