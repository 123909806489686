export const isNull = str => {
  if (typeof str === 'boolean' && (str || !str)) {
    return false;
  }

  if (!str || str === null || typeof str === 'undefined' || str === 'undefined') {
    return true;
  }

  if (typeof str === 'string' && str === '') return true;
  if (Array.isArray(str) && str.length < 1) return true;

  if (
    typeof str === 'object' &&
    str.constructor.name === 'Object' &&
    Object.keys(str).length < 1 &&
    Number(Object.getOwnPropertyNames(str)) < 1
  )
    return true;

  if (typeof str === 'object' && str.constructor.name === 'String' && Object.keys(str).length < 1) return true;

  if (typeof str === 'object' && str.constructor.name === 'Array') {
    let nullFlag = false;
    str.forEach(key => {
      if (key === null || typeof key === 'undefined') {
        nullFlag = true;
      }
    });
    return nullFlag;
  }

  return false;
};
