import { React } from 'assets/provider/libraries';
import * as lb from 'assets/provider/libraries';
import * as md from 'assets/provider/modules';
import * as ct from 'assets/provider/constants';

export const SubList = props => {
  const { list } = props;
  const { details, setOverview } = lb.useContext(md.MenuContext);
  const [anchorEl, setAnchorEl] = lb.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenu = event => {
    setOverview(md.getOverviewByCode(details?.subjects, event.currentTarget.getAttribute('data-code')));
  };

  return (
    <>
      <lb.Box textAlign='right'>
        <lb.Button onClick={handleClick}>
          <lb.Typography color='text.decorate' fontSize='md'>
            {ct.LABEL.LABEL[ct.Label.RELATED_LINK]}
          </lb.Typography>
          <lb.ArrowDropDownIcon sx={{ color: 'text.decorate' }} />
        </lb.Button>
        <lb.Menu
          anchorEl={anchorEl}
          open={open}
          onClick={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          elevation={1}
          slotProps={{
            paper: {
              sx: {
                maxHeight: 'calc(100vh / 2)',
                maxWidth: 'calc(100vw / 1.5)',
                textDecoration: 'none',
                // backgroundColor: 'background.default',
                // borderStyle: 'solid',
                // borderWidth: '1px',
                // borderColor: 'background.dark',
              },
            },
          }}
        >
          {list?.map(
            menu =>
              !(menu[ct.Subjects.DEPTH] === ct.Depth.ONE && menu[ct.Subjects.HAS_SUB]) && (
                <lb.Link
                  key={menu[ct.Subjects.IDX]}
                  to={menu[ct.Subjects.PATH]}
                  data-code={menu[ct.Subjects.CODE]}
                  state={{
                    middleLevelCode: menu[ct.Subjects.MIDDLE_LEVEL_CODE],
                    order: menu[ct.Subjects.ORDER],
                  }}
                  onClick={handleMenu}
                  style={{ color: 'inherit', textDecoration: 'inherit' }}
                >
                  <lb.MenuItem sx={{ padding: '0 auto', margin: '0 auto', whiteSpace: 'normal' }}>
                    <lb.Typography color='text.dark' fontSize='third'>
                      {menu[ct.Subjects.KOR_NAME]}
                    </lb.Typography>
                  </lb.MenuItem>
                </lb.Link>
              ),
          )}
        </lb.Menu>
      </lb.Box>
    </>
  );
};

SubList.propTypes = {
  list: lb.PropTypes.array,
};
