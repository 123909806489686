import { React } from 'assets/provider/libraries';
import * as lb from 'assets/provider/libraries';
import * as md from 'assets/provider/modules';
import * as cm from 'assets/provider/components';
import * as ct from 'assets/provider/constants';
import loadable from '@loadable/component';

function App() {
  const [theme, colorMode] = md.useMode();
  const [menus, setMenus] = lb.useState({
    [ct.Navs.MAIN]: null,
    [ct.Navs.SUB]: null,
    [ct.Navs.ITEM]: null,
    [ct.Navs.USER]: null,
    [ct.Navs.FNB]: null,
  });

  const [details, setDetails] = lb.useState<any>(null);
  const [overview, setOverview] = lb.useState<any>(null);
  const [callback, setCallback] = lb.useState(null);
  const [result, setResult] = lb.useState(null);
  let Element;

  return (
    <md.MenuContext.Provider value={{ menus, setMenus, details, setDetails, overview, setOverview }}>
      <md.QueryContext.Provider value={{ callback, setCallback, result, setResult }}>
        <md.ColorModeContext.Provider value={colorMode}>
          <lb.ThemeProvider theme={theme}>
            <lb.CssBaseline />
            <md.useScrollToTop />
            <lb.HelmetProvider>
              {/* <lb.Box sx={{ position: 'sticky', top: 0, left: 0, right: 0, zIndex: 2 }}> */}
              {/* <lb.AppBar position='sticky'> */}
              <cm.HeaderLayout />
              {/* </lb.AppBar> */}
              {/* </lb.Box> */}
              <div className='container'>
                <lb.Container maxWidth={false} disableGutters>
                  <lb.Container maxWidth='lg'>
                    <main className='content'>
                      <lb.Routes>
                        {details?.menus?.map(
                          detail =>
                            detail[ct.Menus.RESOURCE_NAME] && (
                              <lb.Route
                                key={detail[ct.Menus.IDX]}
                                path={detail[ct.Menus.PATH]}
                                element={
                                  (Element = loadable(() => {
                                    return import(`routes/${detail[ct.Menus.SERVICE_NAME]}/${detail[ct.Menus.RESOURCE_NAME]}`);
                                  })) && <Element id={detail[ct.Menus.IDX]} />
                                }
                              />
                            ),
                        )}
                        {details?.subjects?.map(
                          detail =>
                            detail[ct.Menus.RESOURCE_NAME] && (
                              <lb.Route
                                key={detail[ct.Menus.IDX]}
                                path={detail[ct.Menus.PATH]}
                                element={
                                  (Element = loadable(() => {
                                    return import(`routes/${detail[ct.Menus.SERVICE_NAME]}/${detail[ct.Menus.RESOURCE_NAME]}`);
                                  })) && <Element id={detail[ct.Menus.IDX]} />
                                }
                              />
                            ),
                        )}
                        <lb.Route
                          path='/view/:paramCode/:paramPage'
                          element={
                            (Element = loadable(() => {
                              return import(`routes/bible/Post`);
                            })) && <Element />
                          }
                        />
                        <lb.Route
                          path='/error/400'
                          element={
                            (Element = loadable(() => {
                              return import(`routes/error/Error400`);
                            })) && <Element />
                          }
                        />
                        <lb.Route
                          path='/error/404'
                          element={
                            (Element = loadable(() => {
                              return import(`routes/error/Error404`);
                            })) && <Element />
                          }
                        />
                        <lb.Route
                          path='/error/405'
                          element={
                            (Element = loadable(() => {
                              return import(`routes/error/Error405`);
                            })) && <Element />
                          }
                        />
                      </lb.Routes>
                    </main>
                  </lb.Container>
                </lb.Container>
                <cm.Footer />
              </div>
            </lb.HelmetProvider>
          </lb.ThemeProvider>
        </md.ColorModeContext.Provider>
      </md.QueryContext.Provider>
    </md.MenuContext.Provider>
  );
}

export default App;
