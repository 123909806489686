import json from 'assets/json/common.json';

export const ITEMS = {
  TITLE: 'title',
  CONTENTS: 'contents',
} as const;

export const LABEL = {
  LABEL: json.label,
};

export const RESPONSE = {
  WARNING: json.warning,
  ISNULL: json.warning.null,
  REQUEST: json.warning.request,
  RESPONSE: json.warning.response,
  NETWORK: json.warning.network,
} as const;

export const ERROR_CODE = {
  BAD_REQUEST: '400',
  UNAUTHORIZED: '401',
  NOT_FOUND: '404',
  NOT_ALLOWED: '405',
} as const;
